import { DeviceFormFactor, UserAgent } from './UserAgent';

import Parser from 'ua-parser-js';

export const getUserAgent = (): UserAgent => {
    const ua = new Parser().getResult();
    return {
        Os: ua.os.name,
        OsVersion: ua.os.version,
        Browser: ua.browser.name,
        BrowserVersion: ua.browser.version,
        DeviceVendor: ua.device.vendor,
        DeviceModel: ua.device.model,
        DeviceFormFactor:
            (ua.device.type?.toLowerCase() as DeviceFormFactor) ??
            DeviceFormFactor.Desktop,
        UAString: navigator.userAgent,
        CookiesEnabled: navigator.cookieEnabled,
    };
};
