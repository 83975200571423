import {
    BrowserEventBase,
    BrowserEventType,
    DefaultBrowserEvent,
    PageLoadEvent,
} from './BrowserEvent';

import UserBrowser from '../UserBrowser';

export const buildBrowserEventBase = <T extends BrowserEventType>(
    type: T
): BrowserEventBase<T> => ({
    type,
    sessionId: UserBrowser.SessionId,
    createdAt: new Date().toISOString(),
});

export const buildDefaultEvent = (): DefaultBrowserEvent =>
    buildBrowserEventBase(BrowserEventType.Default);

export const buildPageLoadEvent = (): PageLoadEvent => ({
    ...buildBrowserEventBase(BrowserEventType.PageLoad),
    protocol: window.location.protocol,
    hostname: window.location.hostname,
    pathname: window.location.pathname,
    search: window.location.search,
    href: window.location.href,
});
