import { IDBPDatabase, openDB } from 'idb';

export enum StoreName {
    BrowserEvents = 'BrowserEvents',
}

export const USER_BROWSER_DB_KEY = 'UserBrowserDb';

export const getDb = async (): Promise<IDBPDatabase> => {
    let db: IDBPDatabase;
    try {
        db = await openDB(USER_BROWSER_DB_KEY, 1, {
            upgrade(db) {
                if (!db.objectStoreNames.contains(StoreName.BrowserEvents)) {
                    const eventStore = db.createObjectStore(
                        StoreName.BrowserEvents,
                        {
                            autoIncrement: true,
                        }
                    );

                    eventStore.createIndex('type', 'type');
                    eventStore.createIndex('timestamp', 'timestamp', {
                        unique: true,
                    });
                }
            },
        });
    } catch (err) {
        console.error(`Failed to initialize ${USER_BROWSER_DB_KEY} IDB`, err);
        throw err;
    }

    return db;
};
