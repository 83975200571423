import { v4 } from 'uuid';

const STORAGE_KEY = 'mx-user-browser-session-id';

const createId = () => v4();

const persistId = (id: string): void => {
    try {
        window.sessionStorage.setItem(STORAGE_KEY, id);
    } catch (error) {
        console.warn('Cannot persist session id', { error });
    }
};

const getIdFromStorage = () => {
    try {
        return window.sessionStorage.getItem(STORAGE_KEY);
    } catch (e) {
        return undefined;
    }
};

export const getOrCreateSessionId = () => {
    let sessionId = getIdFromStorage();

    if (!sessionId) {
        sessionId = createId();
        persistId(sessionId);
    }

    return sessionId;
};
