import { Location, LocationType } from './Location';

export const getHTML5Location = async (): Promise<Location> =>
    await new Promise((resolve, reject) => {
        const success: PositionCallback = (position) =>
            resolve({
                type: LocationType.HTML5,
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            });
        const error: PositionErrorCallback = (err) => {
            console.error('Failed to obtain HTML5 Coordinates', {
                name: 'HTML5 Geolocation Error',
                message: err.message,
            });
            reject(err);
        };

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error);
        }
    });
