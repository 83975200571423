export enum DeviceFormFactor {
    Console = 'console',
    Desktop = 'desktop',
    Embedded = 'embedded',
    Mobile = 'mobile',
    SmartTv = 'smarttv',
    Tablet = 'tablet',
    Wearable = 'wearable',
}

export type UserAgent = {
    Os?: string;
    OsVersion?: string;
    Browser?: string;
    BrowserVersion?: string;
    DeviceVendor?: string;
    DeviceModel?: string;
    DeviceFormFactor: string;
    UAString: string;
    CookiesEnabled: boolean;
};
