import { addPageLoadEvent } from './storage';
import { writeUserDataToSession } from '@soluto-private/asurion-pixel';

export { default as UserBrowser } from './UserBrowser';
export * from './storage/BrowserEvent';
export { DeviceFormFactor } from './user-agent';
export { LocationType } from './geolocation';
export type { UserAgent } from './user-agent';
export type { Location } from './geolocation';

export const registerLoadListener = () => {
    window.addEventListener('load', () => {
        void addPageLoadEvent();
    });
};

// Register event listeners
if (typeof window !== 'undefined') {
    registerLoadListener();
}

writeUserDataToSession();
