export enum LocationType {
    HTML5 = 'HTML5',
    IP = 'IP',
}

export type Location = {
    type: LocationType;
    longitude: number;
    latitude: number;
};
