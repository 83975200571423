import {
    BrowserEvent,
    BrowserEventBase,
    BrowserEventType,
} from './BrowserEvent';
import { StoreName, getDb } from './getDb';
import { buildDefaultEvent, buildPageLoadEvent } from './buildEvent';

const EVENT_PAGE_SIZE = 25;

export const getBrowserEvents = async <T extends BrowserEventType>(
    type?: T
): Promise<(BrowserEvent & BrowserEventBase<T>)[]> => {
    let events: (BrowserEvent & BrowserEventBase<T>)[] = [];

    const db = await getDb();
    const eventStore = db
        ?.transaction(StoreName.BrowserEvents, 'readonly')
        ?.objectStore(StoreName.BrowserEvents);
    if (eventStore) {
        try {
            events = (
                type
                    ? await eventStore
                          .index('type')
                          .getAll(IDBKeyRange.only(type), EVENT_PAGE_SIZE)
                    : await eventStore.getAll(null, EVENT_PAGE_SIZE)
            ) as (BrowserEvent & BrowserEventBase<T>)[];
        } catch (err) {
            console.error('Failed to retrieve browser events from store', err);
        }
    }

    return events;
};

export const addDefaultBrowserEvent = async () => {
    await addBrowserEvent(buildDefaultEvent());
};

export const addPageLoadEvent = async () => {
    await addBrowserEvent(buildPageLoadEvent());
};

export const addBrowserEvent = async <T extends BrowserEventType>(
    event: BrowserEventBase<T>
) => {
    const db = await getDb();
    const eventStore = db
        ?.transaction(StoreName.BrowserEvents, 'readwrite')
        ?.objectStore(StoreName.BrowserEvents);
    if (eventStore) {
        try {
            await eventStore.add(event);
        } catch (err) {
            console.error('Failed to add browser event to store', err);
            throw err;
        } finally {
            db.close();
        }
    }
};

export const clearStore = async (name: StoreName) => {
    const db = await getDb();
    const eventStore = db?.transaction(name, 'readwrite')?.objectStore(name);

    await eventStore.clear();
};
