import {
    BrowserEvent,
    BrowserEventBase,
    BrowserEventType,
    getBrowserEvents,
} from './storage';
import { Location, getHTML5Location } from './geolocation';
import { UserAgent, getUserAgent } from './user-agent';
import {
    UserSessionData,
    getUserSessionData,
} from '@soluto-private/asurion-pixel';
import { getOrCreateBrowserId, getOrCreateSessionId } from './identities';

class UserBrowser {
    protected static instance?: UserBrowser;

    Id: string;
    SessionId: string;
    Referrer: string;
    UserAgent: UserAgent;
    UserSessionData: UserSessionData;

    protected constructor() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.Id = getOrCreateBrowserId()!;
        this.SessionId = getOrCreateSessionId();
        this.Referrer = document.referrer;
        this.UserAgent = getUserAgent();
        this.UserSessionData = getUserSessionData();
    }

    public static get(): UserBrowser {
        if (!this.instance) {
            this.instance = new UserBrowser();
        }
        return this.instance;
    }

    public async getLocation(): Promise<Location> {
        const location = await getHTML5Location();
        return location;
    }

    public async getBrowserEvents<T extends BrowserEventType>(
        type?: T
    ): Promise<(BrowserEvent & BrowserEventBase<T>)[]> {
        return await getBrowserEvents(type);
    }
}

export default UserBrowser.get();
