export enum BrowserEventType {
    PageLoad = 'pageLoad',
    Default = 'default',
}

export type BrowserEventBase<T extends BrowserEventType> = {
    type: T;
    sessionId: string;
    createdAt: string;
};

export type PageLoadEvent = BrowserEventBase<BrowserEventType.PageLoad> & {
    protocol: string;
    hostname: string;
    pathname: string;
    search: string;
    href: string;
};

export type DefaultBrowserEvent = BrowserEventBase<BrowserEventType.Default>;

export type BrowserEvent = PageLoadEvent | DefaultBrowserEvent;
